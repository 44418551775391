import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import {
	OverviewREQ, //
	HighlightREQ,
	CompareWithIndexesREQ,
	CompareWithBenchmarkREQ,
	CountryOrIndustryBenchmarkREQ,
	DatasetREQ,
	FullDatasetREQ,
} from './reqTypes';
import {
	PortfolioAnalysisOverviewDTO, //
	PortfolioHighlightDimensionInfoDTO,
	PortfolioComparedWithIndexesDTO,
	ExposurePercentAndDifferencePerDimensionDTO,
	ExposurePercentAndDifferenceOverviewDTO,
	PortfolioCompanyDatasetDTO,
} from './resTypes';
import { IdWithNameDTO } from './types';

const NAME = UrlAPI.newportfolioAnalyzer;

const getOverview = createAppAsyncThunk(`${NAME}/getOverview`, async (arg: { params: OverviewREQ }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.getRequest<PortfolioAnalysisOverviewDTO>({
		url: `${NAME}/overview`,
		params,
		thunkAPI,
	});
});

const getHighlight = createAppAsyncThunk(`${NAME}/getHighlight`, async (arg: { params: HighlightREQ }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.getRequest<PortfolioHighlightDimensionInfoDTO[]>({
		url: `${NAME}/highlight`,
		params,
		thunkAPI,
	});
});

const getCompareWithIndexes = createAppAsyncThunk(`${NAME}/getCompareWithIndexes`, async (arg: { params: CompareWithIndexesREQ }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.getRequest<PortfolioComparedWithIndexesDTO[]>({
		url: `${NAME}/comparewithindexes`,
		params,
		thunkAPI,
	});
});

const getCompareWithBenchmark = createAppAsyncThunk(`${NAME}/getCompareWithBenchmark`, async (arg: { params: CompareWithBenchmarkREQ }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.getRequest<ExposurePercentAndDifferencePerDimensionDTO[]>({
		url: `${NAME}/comparewithbenchmark`,
		params,
		thunkAPI,
	});
});

const getCountryOrIndustryBenchmark = createAppAsyncThunk(`${NAME}/getCountryOrIndustryBenchmark`, async (arg: { params: CountryOrIndustryBenchmarkREQ }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.getRequest<ExposurePercentAndDifferenceOverviewDTO>({
		url: `${NAME}/countryorindustrybenchmark`,
		params,
		thunkAPI,
	});
});

const getDataset = createAppAsyncThunk(`${NAME}/getDataset`, async (arg: { params: DatasetREQ }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.getRequest<PortfolioCompanyDatasetDTO[]>({
		url: `${NAME}/dataset`,
		params,
		thunkAPI,
	});
});

const getFullDataset = createAppAsyncThunk(`${NAME}/getFullDataset`, async (arg: { params: FullDatasetREQ }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.getRequest<PortfolioCompanyDatasetDTO[]>({
		url: `${NAME}/fulldataset`,
		params,
		thunkAPI,
	});
});
// -----------------------------------------------------------------------------------
export const getCompanyLists = createAppAsyncThunk(`${NAME}/getCompanyLists`, async (_: void, thunkAPI) => {
	const { signal } = thunkAPI;
	return await apiRequest.getRequest<IdWithNameDTO[]>({
		url: `${NAME}/companylists`,
		thunkAPI,
		signal,
	});
});

interface State {
	overview: {
		data: PortfolioAnalysisOverviewDTO | null;
		status: RequestStatus;
	};
	highlight: {
		data: PortfolioHighlightDimensionInfoDTO[] | null;
		status: RequestStatus;
	};
	compareWithIndexes: {
		data: PortfolioComparedWithIndexesDTO[] | null;
		status: RequestStatus;
	};
	compareWithBenchmark: {
		data: ExposurePercentAndDifferencePerDimensionDTO[] | null;
		status: RequestStatus;
	};
	countryOrIndustryBenchmark: {
		data: ExposurePercentAndDifferenceOverviewDTO | null;
		status: RequestStatus;
	};
	dataset: {
		data: PortfolioCompanyDatasetDTO[] | null;
		status: RequestStatus;
	};
	fullDataset: {
		data: PortfolioCompanyDatasetDTO[] | null;
		status: RequestStatus;
	};
	selected: {
		menuId: number; // MenuId is dimensionID, at the begin is 0 (no dimensionID is 0, be carefull here)
		chart: {
			title: string | null;
			quartile: string | null;
		};
	};
	companylists: {
		data: IdWithNameDTO[] | null;
		status: RequestStatus;
	};
	status: RequestStatus;
}

export const initialState: State = {
	overview: {
		data: null,
		status: RequestStatus.still,
	},
	highlight: {
		data: null,
		status: RequestStatus.still,
	},
	compareWithIndexes: {
		data: null,
		status: RequestStatus.still,
	},
	compareWithBenchmark: {
		data: null,
		status: RequestStatus.still,
	},
	countryOrIndustryBenchmark: {
		data: null,
		status: RequestStatus.still,
	},
	dataset: {
		data: null,
		status: RequestStatus.still,
	},
	fullDataset: {
		data: null,
		status: RequestStatus.still,
	},
	selected: {
		menuId: 0,
		chart: {
			title: null,
			quartile: null,
		},
	},
	companylists: {
		data: null,
		status: RequestStatus.still,
	},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		resetAnalyzerPage: (state) => {
			// Use once before page loaded
			Object.assign(state, initialState);
		},
		setMenuId: (state, action: { payload: number }) => {
			state.selected.menuId = action.payload;
		},
		setChart: (state, action: { payload: { title: string | null; quartile: string | null } }) => {
			state.selected.chart = { ...action.payload };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getOverview.pending, (state) => {
			state.overview.status = RequestStatus.loading;
		});
		builder.addCase(getOverview.fulfilled, (state, action) => {
			state.overview.data = action.payload;
			state.overview.status = RequestStatus.still;
		});
		builder.addCase(getOverview.rejected, (state) => {
			state.overview.status = RequestStatus.failed;
		});

		builder.addCase(getHighlight.pending, (state) => {
			state.highlight.status = RequestStatus.loading;
		});
		builder.addCase(getHighlight.fulfilled, (state, action) => {
			state.highlight.data = action.payload;
			state.highlight.status = RequestStatus.still;
		});
		builder.addCase(getHighlight.rejected, (state) => {
			state.highlight.status = RequestStatus.failed;
		});

		builder.addCase(getCompareWithIndexes.pending, (state) => {
			state.compareWithIndexes.status = RequestStatus.loading;
		});
		builder.addCase(getCompareWithIndexes.fulfilled, (state, action) => {
			state.compareWithIndexes.data = action.payload;
			state.compareWithIndexes.status = RequestStatus.still;
		});
		builder.addCase(getCompareWithIndexes.rejected, (state) => {
			state.compareWithIndexes.status = RequestStatus.failed;
		});

		builder.addCase(getCompareWithBenchmark.pending, (state) => {
			state.compareWithBenchmark.status = RequestStatus.loading;
		});
		builder.addCase(getCompareWithBenchmark.fulfilled, (state, action) => {
			state.compareWithBenchmark.data = action.payload;
			state.compareWithBenchmark.status = RequestStatus.still;
		});
		builder.addCase(getCompareWithBenchmark.rejected, (state) => {
			state.compareWithBenchmark.status = RequestStatus.failed;
		});

		builder.addCase(getCountryOrIndustryBenchmark.pending, (state) => {
			state.countryOrIndustryBenchmark.status = RequestStatus.loading;
		});
		builder.addCase(getCountryOrIndustryBenchmark.fulfilled, (state, action) => {
			state.countryOrIndustryBenchmark.data = action.payload;
			state.countryOrIndustryBenchmark.status = RequestStatus.still;
		});
		builder.addCase(getCountryOrIndustryBenchmark.rejected, (state) => {
			state.countryOrIndustryBenchmark.status = RequestStatus.failed;
		});

		builder.addCase(getDataset.pending, (state) => {
			state.dataset.status = RequestStatus.loading;
		});
		builder.addCase(getDataset.fulfilled, (state, action) => {
			state.dataset.data = action.payload;
			state.dataset.status = RequestStatus.still;
		});
		builder.addCase(getDataset.rejected, (state) => {
			state.dataset.status = RequestStatus.failed;
		});

		builder.addCase(getFullDataset.pending, (state) => {
			state.dataset.status = RequestStatus.loading;
		});
		builder.addCase(getFullDataset.fulfilled, (state, action) => {
			state.fullDataset.data = action.payload;
			state.dataset.status = RequestStatus.still;
		});
		builder.addCase(getFullDataset.rejected, (state) => {
			state.dataset.status = RequestStatus.failed;
		});
		// ------------------------------------------------------
		builder.addCase(getCompanyLists.pending, (state) => {
			state.companylists.status = RequestStatus.loading;
		});
		builder.addCase(getCompanyLists.fulfilled, (state, action) => {
			state.companylists.data = action.payload;
			state.companylists.status = RequestStatus.still;
		});
		builder.addCase(getCompanyLists.rejected, (state) => {
			state.companylists.status = RequestStatus.failed;
		});
	},
});

export const actionsAnalyzerPageNew = {
	...slice.actions,
	getOverview,
	getHighlight,
	getCompareWithIndexes,
	getCompareWithBenchmark,
	getCountryOrIndustryBenchmark,
	getDataset,
	getFullDataset,
	getCompanyLists,
};
