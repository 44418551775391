import { createSlice } from '@reduxjs/toolkit';
import { CompanyName } from './types';

const NAME = 'peercompany';

// * Reducer
export interface State {
	company?: CompanyName | null;
	peers?: CompanyName[];
}

export const initialState: State = {
	company: null,
	peers: [],
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		addCompanyAndPeers: (state, action: { payload: { company: CompanyName | null; peers: CompanyName[] } }) => {
			if (action.payload.company) state.company = action.payload.company;
			state.peers = action.payload.peers;
		},
		clearCompanyAndPeers: (state) => {
			state.company = null;
			state.peers = [];
		},
		addCompany: (state, action: { payload: CompanyName }) => {
			state.company = action.payload;
		},
		addPeer: (state, action: { payload: CompanyName }) => {
			state.peers = [...(state.peers ?? []), action.payload];
		},
		removePeer: (state, action: { payload: { index: number } }) => {
			state.peers?.filter((_, index) => index !== action.payload.index);
		},
	},
});

export const actionBenchmark = {
	...slice.actions,
};
