export enum MenuOptionNew {
	'Create portfolio',
	'Select collection',
	'Select peer group',
}

export enum MenuOptionView {
	'Saved analysis',
	'Create analysis',
}
