export enum RequestStatus {
	still = 'STILL',
	loading = 'LOADING',
	failed = 'FAILED',
}

export enum UrlAPI {
	// Authentication
	login = 'login',
	register = 'register',
	dictionaries = 'dictionaries',
	columnsMetadata = 'columnsMetadata',

	// Main page lookup search
	companiesLookup = 'companiesLookup',
	industriesLookup = 'industriesLookup',
	countriesLookup = 'countriesLookup',
	savedColumns = 'savedColumns',

	// Main page saved queries
	savedQueries = 'savedQueries',
	commonSavedQueries = 'commonSavedQueries',
	userSavedQueries = 'userSavedQueries',

	// Main search results
	companies = 'companies',
	industries = 'industries',
	countries = 'countries',

	// Database results
	company = 'company',
	industry = 'industry',
	country = 'country',

	// ChatGPT
	ai = 'ai',

	// Portfolio analyzer
	portfolioAnalyzer = 'portfolioanalyzer',
	newportfolioAnalyzer = 'newportfolioanalyzer',

	// Funds
	newfunds = 'newfunds',

	// Benchmark
	peerCompany = 'peercompany',
}
