import { configureStore } from '@reduxjs/toolkit';
import { loadState, saveState } from './localStorage';
import { rootReducer } from './rootReducer';

import { initialState as database } from '../../pages/DatabasePage/api/database/slice';
import { initialState as databaseNew } from '../../pages/DatabaseNewPage/api/database/slice';
import { initialState as chatGPT } from '../../pages/MainPage/api/chatGPT/slice';
import { initialState as mainSearchExcel } from '../../pages/MainSearchResultsPage/api/excelExport/slice';
import { initialState as notifications } from '../_BLL/notifications/slice';
import { initialState as savedQueries } from 'src/pages/MainPage/api/savedQueries/slice';
import { initialState as benchmarkSearch } from 'src/pages/MainPage/ui/_content/benchmark/_BLL/slice';
import { initialState as analyzerSearch } from 'src/pages/MainPage/ui/_content/analyzer/_BLL/slice';

// Portfolio Analyzer
import { initialState as portfolioAnalyzer } from 'src/pages/portfolio_analyzer_pages/api/portfolio_analyzer/slice';
import { initialState as portfolioTable } from 'src/pages/portfolio_analyzer_pages/api/portfolio_table/slice';
import { initialState as portfolioConfig } from 'src/pages/portfolio_analyzer_pages/api/portfolio_config/slice';

import { initialState as portfolioAnalyzerNew } from 'src/pages/portfolio_analyzer_pages_new/api/analyzerPage/slice';
import { initialState as newAnalysis } from 'src/pages/portfolio_analyzer_pages_new/api/analyzer/slice';

// Funds
import { initialState as fundPage } from 'src/pages/funds_pages_new/api/slice';

// Benchmark
import { initialState as benchmark } from 'src/pages/BenchmarkPage/api/benchmark/slice';

const preloadedState = loadState();

export const store = configureStore({
	reducer: rootReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({
			immutableCheck: false,
		}),
	devTools: { trace: false },
	preloadedState,
});

store.subscribe(() => {
	saveState({
		notifications,
		auth: store.getState().auth,

		mainSearch: store.getState().mainSearch,
		booleanSearch: store.getState().booleanSearch,
		savedQueries,
		benchmarkSearch,
		analyzerSearch,

		mainSearchResults: store.getState().mainSearchResults,
		mainSearchExcel,
		database,
		databaseNew,
		customizeColumns: store.getState().customizeColumns,
		savedColumns: store.getState().savedColumns,
		chatGPT,

		// Portfolio analyzer
		portfolioAnalyzer,
		portfolioTable,
		portfolioConfig,

		// Portfolio analyzer new
		portfolioAnalyzerNew,
		newAnalysis,

		// Funds
		fundPage,
		benchmark,
	});
});

export type AppDispatch = typeof store.dispatch;
