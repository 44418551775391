import { createSlice } from '@reduxjs/toolkit';
import { createAppAsyncThunk } from 'src/app/redux/createAction';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import {
	CompanyListUpdateRequestDTO, //
	DimensionListUpdateRequestDTO,
	IndexListUpdateRequestDTO,
	PortfolioAnalysisUpdateRequestDTO,
	PortfolioAnalysisTemporaryRequestDTO,
} from './reqTypes';
import {
	MetadataDTO, //
	UserInfoDTO,
	UploadPortfolioRES,
	CompanyListDTO,
	DimensionListDTO,
	IndexListDTO,
	PortfolioAnalysisDTO,
} from './resTypes';

const NAME = UrlAPI.newportfolioAnalyzer;

export const getMetadata = createAppAsyncThunk(`${NAME}/getMetadata`, async (_: void, thunkAPI) => {
	const { signal } = thunkAPI;
	return await apiRequest.getRequest<MetadataDTO>({
		url: `${NAME}/metadata`,
		thunkAPI,
		signal,
	});
});

export const getUserinfo = createAppAsyncThunk(`${NAME}/getUserinfo`, async (_: void, thunkAPI) => {
	const { signal } = thunkAPI;
	return await apiRequest.getRequest<UserInfoDTO>({
		url: `${NAME}/userinfo`,
		thunkAPI,
		signal,
	});
});

// ---------------------------------------------------------------------------
export const uploadPortfolio = createAppAsyncThunk(`${NAME}/uploadPortfolio`, async (arg: { payload: FormData }, thunkAPI) => {
	const { payload } = arg;

	return await apiRequest.postFormDataRequest<UploadPortfolioRES>({
		url: `${NAME}/excel`,
		payload,
		thunkAPI,
	});
});
// ---------------------------------------------------------------------------

export const postCompanyList = createAppAsyncThunk(`${NAME}/postCompanyList`, async (arg: CompanyListUpdateRequestDTO, thunkAPI) => {
	return await apiRequest.postRequest<CompanyListDTO>({
		url: `${NAME}/companylist`,
		payload: arg,
		thunkAPI,
	});
});

export const delCompanyList = createAppAsyncThunk(`${NAME}/delCompanyList`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	await apiRequest.delRequest({
		url: `${NAME}/companylist`,
		params,
		thunkAPI,
	});
});

export const postDimensionList = createAppAsyncThunk(`${NAME}/postDimensionList`, async (arg: DimensionListUpdateRequestDTO, thunkAPI) => {
	return await apiRequest.postRequest<DimensionListDTO>({
		url: `${NAME}/dimensionlist`,
		payload: arg,
		thunkAPI,
	});
});

export const delDimensionList = createAppAsyncThunk(`${NAME}/delDimensionList`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	await apiRequest.delRequest({
		url: `${NAME}/dimensionlist`,
		params,
		thunkAPI,
	});
});

export const postIndexList = createAppAsyncThunk(`${NAME}/postIndexList`, async (arg: IndexListUpdateRequestDTO, thunkAPI) => {
	return await apiRequest.postRequest<IndexListDTO>({
		url: `${NAME}/indexlist`,
		payload: arg,
		thunkAPI,
	});
});

export const delIndexList = createAppAsyncThunk(`${NAME}/delIndexList`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;

	await apiRequest.delRequest({
		url: `${NAME}/indexlist`,
		params,
		thunkAPI,
	});
});

export const saveAnalysis = createAppAsyncThunk(`${NAME}/saveAnalysis`, async (arg: PortfolioAnalysisUpdateRequestDTO, thunkAPI) => {
	return await apiRequest.postRequest<PortfolioAnalysisDTO>({
		url: `${NAME}/analysis`,
		payload: arg,
		thunkAPI,
	});
});

export const saveTempAnalysis = createAppAsyncThunk(`${NAME}/saveTempAnalysis`, async (arg: PortfolioAnalysisTemporaryRequestDTO, thunkAPI) => {
	return await apiRequest.postRequest<number>({
		url: `${NAME}/analysis/tmp`,
		payload: arg,
		thunkAPI,
	});
});

export const removeAnalysis = createAppAsyncThunk(`${NAME}/removeAnalysis`, async (arg: { params: { id: number } }, thunkAPI) => {
	const { params } = arg;
	return await apiRequest.delRequest({
		url: `${NAME}/analysis`,
		params,
		thunkAPI,
	});
});

interface State {
	meta: {
		data: MetadataDTO | null;
		status: RequestStatus;
	};
	userinfo: {
		data: UserInfoDTO | null;
		status: RequestStatus;
	};
	selected: {
		template: File | null;
		removeCompaniesList: boolean;
		removeDimensionsList: boolean;
		removeIndexesList: boolean;
		selectedCompaniesList: CompanyListDTO | null; // portfolio
		selectedDimensionsList: DimensionListDTO | null; // scores / dimensions / collections
		selectedIndexesList: IndexListDTO | null; // peer groups
	};
	status: RequestStatus;
}

export const initialState: State = {
	meta: {
		data: null,
		status: RequestStatus.still,
	},
	userinfo: {
		data: null,
		status: RequestStatus.still,
	},
	selected: {
		template: null,
		removeCompaniesList: true,
		removeDimensionsList: true,
		removeIndexesList: true,
		selectedCompaniesList: null,
		selectedDimensionsList: null,
		selectedIndexesList: null,
	},
	status: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setTemplate: (state, action: { payload: File | null }) => {
			state.selected.template = action.payload;
		},
		setRemoveCompaniesList: (state, action: { payload: boolean }) => {
			state.selected.removeCompaniesList = action.payload;
		},
		setRemoveDimensionsList: (state, action: { payload: boolean }) => {
			state.selected.removeDimensionsList = action.payload;
		},
		setRemoveIndexesList: (state, action: { payload: boolean }) => {
			state.selected.removeIndexesList = action.payload;
		},
		setSelectedCompaniesList: (state, action: { payload: CompanyListDTO | null }) => {
			state.selected.selectedCompaniesList = action.payload;
		},
		setSelectedDimensionsList: (state, action: { payload: DimensionListDTO | null }) => {
			state.selected.selectedDimensionsList = action.payload;
		},
		setSelectedIndexesList: (state, action: { payload: IndexListDTO | null }) => {
			state.selected.selectedIndexesList = action.payload;
		},
		resetSelected: (state) => {
			state.selected = { ...initialState.selected };
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getMetadata.pending, (state) => {
			state.meta.status = RequestStatus.loading;
		});
		builder.addCase(getMetadata.fulfilled, (state, action) => {
			state.meta.data = action.payload;
			state.meta.status = RequestStatus.still;
		});
		builder.addCase(getMetadata.rejected, (state) => {
			state.meta.status = RequestStatus.failed;
		});

		builder.addCase(getUserinfo.pending, (state) => {
			state.userinfo.status = RequestStatus.loading;
		});
		builder.addCase(getUserinfo.fulfilled, (state, action) => {
			state.userinfo.data = action.payload;
			state.userinfo.status = RequestStatus.still;
		});
		builder.addCase(getUserinfo.rejected, (state) => {
			state.userinfo.status = RequestStatus.failed;
		});

		builder.addCase(uploadPortfolio.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(uploadPortfolio.fulfilled, (state) => {
			state.status = RequestStatus.still;
		});
		builder.addCase(uploadPortfolio.rejected, (state) => {
			state.status = RequestStatus.failed;
		});

		builder.addCase(delCompanyList.pending, (state) => {
			state.userinfo.status = RequestStatus.loading;
		});
		builder.addCase(delCompanyList.fulfilled, (state) => {
			state.userinfo.status = RequestStatus.still;
		});
		builder.addCase(delCompanyList.rejected, (state) => {
			state.userinfo.status = RequestStatus.failed;
		});

		builder.addCase(removeAnalysis.pending, (state) => {
			state.userinfo.status = RequestStatus.loading;
		});
		builder.addCase(removeAnalysis.fulfilled, (state) => {
			state.userinfo.status = RequestStatus.still;
		});
		builder.addCase(removeAnalysis.rejected, (state) => {
			state.userinfo.status = RequestStatus.failed;
		});
	},
});

export const actionsPortfolioAnalyzerNew = {
	...slice.actions,
	getMetadata,
	getUserinfo,
	uploadPortfolio,

	postCompanyList,
	delCompanyList,

	postDimensionList,
	delDimensionList,

	postIndexList,
	delIndexList,

	saveAnalysis,
	saveTempAnalysis,
	removeAnalysis,
};
