import { createSlice } from '@reduxjs/toolkit';
import { MenuOptionNew, MenuOptionView } from './types';

const NAME = 'analyzer';

// * Reducer
export interface State {
	selectedNew: MenuOptionNew;
	selectedView: MenuOptionView;
}

export const initialState: State = {
	selectedNew: MenuOptionNew['Create portfolio'],
	selectedView: MenuOptionView['Saved analysis'],
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setSelectedNew: (state, action: { payload: MenuOptionNew }) => {
			state.selectedNew = action.payload;
		},
		setSelectedView: (state, action: { payload: MenuOptionView }) => {
			state.selectedView = action.payload;
		},
		clearAll: (state) => {
			state.selectedNew = initialState.selectedNew;
			state.selectedView = initialState.selectedView;
		},
	},
});

export const actionAnalyzer = {
	...slice.actions,
};
