import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { actionsBooleanSearch } from 'src/pages/MainPage/api/booleanSearch/slice';
import { ResponseStatus, SearchResult } from 'src/pages/MainPage/api/booleanSearch/types';
import { apiRequest } from 'src/shared/api/api';
import { RequestStatus, UrlAPI } from 'src/shared/api/types';
import { wait } from 'src/shared/lib/async';
import { HeaderId, SearchTabId } from 'src/shared/types/header';
import { IPagination } from 'src/shared/types/pagination';
import { createAppAsyncThunk } from '../../../../app/redux/createAction';
import { parseCompaniesForTable, parseResultsForTable } from '../_lib';
import { SelectedColumn } from '../customizeColumns/types';
import { Body, BodyCompany, ParsedForTable, SearchResultCompany, SearchResultIndustryCountry } from './types';

const NAME = 'searchResults';

const getSearchResults = createAppAsyncThunk(`${NAME}/getSearchResults`, async (args: { url: 'companies' | 'industries' | 'countries'; payload: Body | BodyCompany }, thunkAPI) => {
	const { getState, dispatch } = thunkAPI;
	const state = getState();

	const { url, payload } = args;

	let res: any;

	const search = state.mainSearch.data.search;
	const mode = state.mainSearch.data.searchMode;
	const activeColumns = state.customizeColumns.activeColumns;

	// * Boolean search related.
	const booleanSearchResults = state.booleanSearch.searchResults;

	if (mode === 'lookup') {
		const params = {
			search_prefix: search,
			page_index: payload.pagination.pageIndex,
			page_size: payload.pagination.pageSize,
		};

		if (url === 'industries' || url === 'countries') res = await apiRequest.getRequest({ url, params, thunkAPI });
	}

	if (mode === 'options') {
		if (url === 'industries' || url === 'countries') res = await apiRequest.postRequest({ url, payload, thunkAPI });
	}

	if (url === 'companies') {
		let status: ResponseStatus = null;
		let queryId: number | null = null;

		do {
			if (mode === 'companiesBoolean' && booleanSearchResults) {
				// @ts-ignore
				const booleanBody = {
					// TODO: Remove TS ignores by making type guards.
					// @ts-ignore
					columnsWithPeriods: payload?.columnsWithPeriods,
					searchId: booleanSearchResults.result.searchId,
					// @ts-ignore
					associatedDataTakenFromCompany: payload?.associatedDataTakenFromCompany,
					pagination: payload?.pagination,
					queryId,
				};

				try {
					res = await apiRequest.postRequest({ url, payload: queryId !== null ? { queryId } : booleanBody, thunkAPI });

					dispatch(actionsSearchResults.setLastCompanyRequest(booleanBody));
				} catch (error) {
					const lastSearchRequest = state.booleanSearch.lastSearchRequest;

					if ((error.errorCode === 'CACHE_EXPIRED' && lastSearchRequest) || lastSearchRequest) {
						const booleanSearchRepeatResult = await apiRequest.postRequest<SearchResult>({
							url: `${UrlAPI.companies}/booleanSearch`,
							payload: lastSearchRequest,
							thunkAPI,
						});

						dispatch(actionsBooleanSearch.setSearchResults(booleanSearchRepeatResult));

						// @ts-ignore
						const booleanBody: BodyCompany = {
							// TODO: Remove TS ignores by making type guards.
							// @ts-ignore
							columnsWithPeriods: payload?.columnsWithPeriods,
							searchId: booleanSearchRepeatResult.result.searchId,
							// @ts-ignore
							associatedDataTakenFromCompany: payload?.associatedDataTakenFromCompany,
							pagination: payload?.pagination,
							queryId,
						};

						res = await apiRequest.postRequest({ url, payload: queryId !== null ? { queryId } : booleanBody, thunkAPI });
						dispatch(actionsSearchResults.setLastCompanyRequest(booleanBody));
					} else {
						return thunkAPI.rejectWithValue(error);
					}
				}
			} else {
				res = await apiRequest.postRequest({ url, payload: queryId !== null ? { queryId } : payload, thunkAPI });
				dispatch(actionsSearchResults.setLastCompanyRequest(payload));
			}

			// @ts-ignore
			queryId = res?.queryId;
			// @ts-ignore
			status = res?.status;

			if (res?.queryId && res.status === 'InProgress') {
				await wait(3000);
			}
		} while (status === 'InProgress');
	}

	// TODO: Fix the shitty typing.
	return {
		res: res,
		activeColumns,
	} as {
		res: any;
		activeColumns: SelectedColumn[];
	};
});

// * Reducer
interface State {
	data: {
		searchResults: Array<SearchResultCompany | SearchResultIndustryCountry> | null;
		pagination: IPagination;
		parsedForTable: ParsedForTable[] | null;
	};
	header: HeaderId;
	tab: SearchTabId;
	associatedDataTakenFromCompany: boolean;
	lastCompanyRequest: BodyCompany | null; // ! Required in portfolio analyzer.
	error: string | null;
	status: RequestStatus;
	excelStatus: RequestStatus;
}

const initialState: State = {
	data: {
		searchResults: null,
		pagination: {
			pageIndex: 0,
			pageSize: 10,
			pageCount: 0,
			count: 0,
		},
		parsedForTable: null,
	},
	header: 'data',
	tab: 'company',
	associatedDataTakenFromCompany: false,
	lastCompanyRequest: null,
	error: null, // TODO: Comment on error.
	status: RequestStatus.still,
	excelStatus: RequestStatus.still,
};

export const slice = createSlice({
	name: NAME,
	initialState,
	reducers: {
		setHeader: (state, action: { payload: HeaderId }) => {
			state.header = action.payload;
		},
		setTab: (state, action: { payload: SearchTabId }) => {
			state.tab = action.payload;
		},
		setPagination: (state, action: { payload: IPagination }) => {
			state.data.pagination = action.payload;
		},
		setAssociatedDataTakenFromCompany: (state, action: PayloadAction<boolean>) => {
			state.associatedDataTakenFromCompany = action.payload;
		},
		clear: (state) => {
			state.data = initialState.data;
			state.error = null;
		},
		clearAll: (state) => {
			state.data = initialState.data;
			state.header = initialState.header;
			state.tab = initialState.tab;
			state.associatedDataTakenFromCompany = initialState.associatedDataTakenFromCompany;
			state.error = initialState.error;
			state.status = initialState.status;
			state.excelStatus = initialState.excelStatus;
		},
		setLastCompanyRequest: (state, action) => {
			state.lastCompanyRequest = action.payload;
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getSearchResults.pending, (state) => {
			state.status = RequestStatus.loading;
		});
		builder.addCase(getSearchResults.fulfilled, (state, action) => {
			const { res, activeColumns } = action.payload;

			let parsedForTable: ParsedForTable[] = [];

			if ('result' in res) {
				parsedForTable = res.result.companies.length !== 0 ? parseCompaniesForTable(res.result.companies, activeColumns) : [];
			} else {
				const dataPoints = res.industries || res.countries;
				parsedForTable = parseResultsForTable(dataPoints);
			}

			const pagination =
				'result' in res //
					? state.data.pagination.pageCount !== res.result.pagination.pageCount
						? res.result.pagination
						: state.data.pagination //
					: state.data.pagination.pageCount !== res.pagination.pageCount
						? res.pagination
						: state.data.pagination; //

			state.data = {
				searchResults: res.result?.companies || res.industries || res.countries,
				pagination,
				parsedForTable,
			};
			state.status = RequestStatus.still;
			state.error = null;
		});
		builder.addCase(getSearchResults.rejected, (state) => {
			state.status = RequestStatus.failed;
		});
	},
});

export const actionsSearchResults = {
	...slice.actions,
	getSearchResults,
};
